import React from 'react'
import Layout from '../components/Layout'
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <section className="hero gradientBg is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="container center">
          <article className="media">
            <div className="media-content">
              <div className="content">
                <h1 className="center is-size-1 has-text-white">Oh dear</h1>
                <p className="center has-text-white is-size-3">
                  That page isn&apos;t where we thought we left it. <br />
                  If you head back we&apos;ll have a look round and try to find it.
                </p>
                <Link className="button has-text-link" to="/">
                  Teddy Story Home
                </Link>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
